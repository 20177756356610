.forgot-password {
    cursor: pointer;
    display: flex;
    justify-content: right;
}

.btn-container {
    display: flex;
    justify-content: space-between;
}

.btn-closed-tik {
    width: 160px;
    background-color: darkred;
    font-weight: normal;
}

.btn-open-tik {
    background-color: green;
}

.ticket-start {
    padding: 9px 17px;
    cursor: pointer;
    margin-right: 1rem;
    border: 0;
    background: var(--primary-color);
    color: white;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    font-style: italic;
}

.input-wrapper {
    display: flex;
    align-items: center;
}

.edit-icon {
    font-size: 1.3rem;
    cursor: pointer;
    padding-left: 5px;
}

.is-on-priority {
    text-align: left;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.is-on-priority>label {
    margin-right: 1rem;
}

.ticket-label {
    cursor: pointer;
}

.auth-card {
    padding: 24px 24px 48px 24px;
}

.css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
}