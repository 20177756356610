.header-logo {
  display: flex;
  align-items: center;
}

.header-text {
  font-weight: bold;
  margin-left: -2px;
}

.user {
  color: var(--primary-color);
  font-size: 1;
  display: flex;
  align-items: center;
}

.user-edit-icon {
  color: black;
  margin-left: 3px;
  cursor: pointer;
  padding-bottom: 2px;
}

.primary-text {
  color: var(--primary-color);
}

.status-urgent {
  background-color: #cb2306;
  margin-left: 5px;
}